.createUserContainer {
  position: absolute;
  top: 15%;
  left: 410px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 430px;
  height: 540px;
  background-color: white !important;
  border-radius: 10px;
  box-shadow: 0px 5px 16px rgb(0 0 0 / 55%);
  z-index: 5;
}

.createUserTitle {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 22px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #0c3957;
  padding-left: 30px;
  padding-top: 10px;
}

.createUserNameInputs {
  display: flex;
  justify-content: space-between;
  padding-left: 30px;
  width: 370px;
  position: relative;
  top: 30px;
}

.createUserNameInput {
  font-weight: bold;
  font-size: 16px;
  color: #0c3957;
}

.createUserPassword {
  font-size: 20px;
  width: 100% !important;
  top: 10px !important;
}

.createUserPasswordField {
  position: relative;
  top: 110px;
  user-select: none;
}

.createUserPasswordInput {
  top: 30px;
  padding-left: 30px;
  position: relative;
  padding-bottom: 30px;
}

.createUserNameInputBox {
  background: #f5f8fa;
  border: none;
  border-radius: 4px;
  height: 26px;
  padding: 10px 23px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #7a7a7a;
  width: 130px;
  text-align: start;
}

.createUserInputBox {
  background: #f5f8fa;
  border: none;
  border-radius: 4px;
  height: 26px;
  padding: 10px 23px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #7a7a7a;
  text-align: start;
  width: 317px;
}

.createUserInputTitle {
  padding-left: 30px;
  position: relative;
  top: 50px;
  width: 80%;
}

.createUserRoleButtons {
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  position: relative;
  top: 20px;
  width: 315px;
}

.x {
  position: relative;
  left: 390px;
  top: 10px;
  width: 23px;
  height: 23px;
  border: 2px solid #bdbdbd;
  background-color: white;
  border-radius: 50%;
  opacity: 0.5;
}
.x::before,
.x::after {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 13px;
  height: 3px;
  content: "";
  background-color: #7a7a7a;
}
.x::before {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.x::after {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.x:hover {
  cursor: pointer;
  opacity: 1;
}
.x:hover::before,
.x:hover::after {
  display: block;
}

.createUserErrorMessage {
  font-weight: normal;
  font-size: 12px;
  color: #b40125;
  height: 15px;
}

.createUserType {
  top: 70px;
}
