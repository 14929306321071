.mainChartsContainer {
	margin-left: 40px;
	display: flex;
	align-items: flex-start;
	width: 1280px;
}

.retirmentBox {
	background: #ffffff;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	height: 552px;
}

.retirementFactors {
	width: 326px;
}

.retirementFactorsHeader {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 30px;
	color: #0c3957;
	margin-top: 10px;
	margin-left: 10px;
}

.retirementFactorsDivider {
	margin-left: 10px;
	margin-bottom: 10px;
	height: 10px;
}

.startValue {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
	color: #0c3957;
	margin-left: 10px;
}

.startValueValue, .startValueValue:focus {
	background: #f5f8fa;
	border: none;
	width: 256px;
	height: 24px;
	padding: 10px 23px;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	display: flex;
	align-items: center;

	/* Text/Copy/Primary */

	color: #0C3957;
	outline: none;
}

.grossMonthlyContributions {
	margin-left: 18px;
	display: flex;
	align-items: center;
}

.grossMonthlyContributionsHeader {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #0c3957;
}

.grossMonthlyContributionsValue {
	position: absolute;
	left: 100px;
	top: 307px;
	bottom: 49.88%;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #0c3957;
}

.retirementChart {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 854px;
	margin-left: 20px;
}

.taxRateFilledButtonCardHandlerContainer {
	margin: 0px;
	padding: 0px;
	height: 36px;
}

.emptyTaxButtonHandlerContainer {
	margin-top: 0px;
	padding: 0px;
	height: 36px;
}

.selectTaxRate {
margin-top: 10px;
}

.taxRateHeaderContainer {
	display: flex;
}

.selectTaxRateHeader {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
	color: #0c3957;
	display: flex;
	align-items: center;
	width: 230px;
}

.taxButtons {
	display: flex;
	flex-direction: column;
	height: 180px;
	width: 326px;
}

.taxText {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	/* or 162% */

	display: flex;
	align-items: center;
	justify-content: center;
	letter-spacing: 0.01em;
	height: 36px;
	padding: 0px;
	margin-left: 5px;

	/* Text/Copy/Primary */

	color: #0c3957;
}

.taxBold {
	font-weight: bold;
}

.tax {
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 15px;
	margin-top: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.chargeFees {
	margin-top: 20px;
	margin-left: 10px;
}

.chargeFeesLabel {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
	/* identical to box height, or 162% */

	/* Text/Copy/Primary */

	color: #0c3957;
}

.chargeFeesDropdownIcon {
	margin-top: 5px;
	margin-left: 20px;
}

.taxRatesDropdownIcon {
	margin-top: 5px;
	margin-left: 20px;

}

.dropdownIsOpen {
	transform: scaleY(-1);
	margin-top: 0px;
}

.fees {
	display: flex;
	flex-direction: column;
}

.chartsWarning {
	position: absolute;
	top: 600px;
	left: 400px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.chartsWarningIcon {
	transform: scale(0.5);
	position: absolute;
	top: 635px;
	left: 510px;
}

.chartsWarningText {
	position: absolute;
	top: 640px;
	left: 541px;
	width: 800px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 26px;
	/* identical to box height, or 186% */

	display: flex;
	align-items: center;

	/* Text/Copy/Secondary */

	color: #7A7A7A;
}

.startValueUnit {
	position: absolute;
    top: 220px;
    left: 62px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	display: flex;
	align-items: center;

	/* Text/Copy/Primary */

	color: #0C3957;
}

.netMonthlyContributionsUnit {
	position: absolute;
	top: 289.7px;
	left: 62px;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	display: flex;
	align-items: center;

	/* Text/Copy/Primary */

	color: #0C3957;
}

.graphHeader {
	position: absolute;
	top: 200px;
	left: 500px;
}

li { padding: 5px 0px 0px; }
li:first-child { margin-bottom: 0px; }