.cardContainer {
	padding-top: 50px;
	height: 633px;
	width: 691px;
}

.aboutCardHeader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 43px !important;
	font-family: Brother 1816;
	font-style: normal !important;
	font-weight: bold !important;
	font-size: 30px !important;
	line-height: 43px !important;
	color: #bf802f !important;
}

.aboutCardText {
	padding-top: 10px;
	height: 90px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 21px;
	line-height: 30px;
	text-align: center;

	/* Text/Copy/Primary */

	color: #0c3957;
}

.imageContainer {
	padding-top: 40px;
}
