.aboutAmmoniteCard {
  width: 555px;
  /* height: 417px; */
  margin-left: 20px;
  margin-top: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #ffffff;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.aboutAmmoniteLogoContainer {
  margin-top: -10px;
  margin-left: -15px;
  margin-bottom: 45.96px;
  height: 54.73px;
  width: 167px;
  transform: scale(0.5);
}

.aboutAmmoniteParagraphFirst {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 515px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #0c3957;
}

.aboutAmmoniteParagraph {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 515px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0c3957;
  display: flex;
}

.bulletPoint {
  width: 20px;
  margin-right: 10px;
}
