.checkboxContainer {
	display: flex;
	align-items: center;
	margin-top: 25px;
	height: 44px;
}

.checkboxUnchecked {
	min-width: 36px;
	max-width: 36px;
	height: 36px;
	border: 4px solid #3bb9c4;
	box-sizing: border-box;
	border-radius: 8px;
	background: #FFFFFF;
}

.checkboxChecked {
	position: relative;
	min-width: 36px;
	max-width: 36px;
	height: 36px;
	border: none;
}

.checkboxText {
	margin: 0px 15px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
	color: #0c3957;
	user-select: none;
}

.isBold {
	font-weight: 800;
}

.isInline {
	margin-top: 27px !important;
}

.halfSize {
	transform: scale(0.5);
}
