.sortOutContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 15px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	/* or 139% */

	display: flex;
	align-items: center;

	/* Text/Copy/Primary */

	color: #0c3957;
}

.sortOutText {
	margin-left: 10px;
}
