:root {
    /* (...) */
    --light: 85;
    --border-threshold: 80;
  }

.graphicContainer {
    width: 555px;
    height: 567px;
    margin-left: 20px;
    margin-top: 30px;

    /* white */

    background: #FFFFFF;
    /* box/standard */

    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
}

.graphicHeader {
    width: 515px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    z-index: 4;
    /* or 139% */


    /* Text/Copy/Primary */

    color: #0C3957;
}

.graphicIntro {
    width: 515px;
    padding-top: 9.84px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* or 137% */


    /* Text/Copy/Primary */

    color: #0C3957;
}

.graphic {
    position: absolute;
    height: 270px;
    top: 60px;
    display: flex;
    align-items: center;
}

.amountDotsContainer {
    position: absolute;
    top: 0;
    height: 550px;
    width: 515px;
}

.graphicLine {
    position: absolute;
    margin-left: 20px;
}

.timeLine {
    position: absolute;
    top: 60px
}

.dateDot {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lineDate {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #7A7A7A;
    mix-blend-mode: difference;
}

.lineDot {
    margin-left: 15px;
   
}

.bigDot {
    margin-left: -110px;
    margin-top: -100px;
}