.coveredContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5px;
	padding-top: 10px;
	padding-bottom: 5px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	/* or 139% */

	display: flex;
	align-items: center;

	/* Text/Copy/Primary */

	color: #0c3957;
}

.coveredTick {
	height: 30px;
}

.coveredText {
	width: 247px;
}