.actionsContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 21px;
	margin-left: 40px;
	width: 1280px;
}

.toast {
	position: absolute;
	top: 30px;
	left: 740px;
	background-color: #ffffff;
	z-index: 5;
}

.displayToast {
	margin: 0 0 0 0px;
}

.actionToDoCard {
	width: 329px;
	height: 609px;
	background: #ffffff;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 30px;

	/* Text/Copy/Primary */

	color: #0c3957;
}

.actionAmmoniteCard {
	width: 500px;
	height: 609px;
	margin-left: 50px;
	background: #ffffff;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 30px;
	color: #0c3957;
}

.actionCardLabel {
	padding-top: 30px;
	padding-left: 20px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 30px;
	color: #0c3957;
}

.actionToDoDivider {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;
}

.actionsToDo {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding-left: 20px;
}

.toSortOut {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 10px;
	margin-left: 76px;
}

.toSortOutList {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.successIllustration {
	position: absolute;
	left: 510.59px;
	top: 440px;
}

.summaryTextContainer {
	position: absolute;
	left: 516px;
	top: 336px;
}

.summaryText {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 23px;
	text-align: center;

	/* Text/Copy/Primary */

	color: #0C3957;
}

.summaryTextBold {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	color: #0C3957;
}
