.personCardAdditional {
    width: 567px;
    height: 509px;
    margin-right: 45px;
    background-color: #ffffff;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.personCardAdditionalName {
    position: relative;
    height: 29.52px;
    top: 15px;
    left: 27px;
    /* margin-bottom: 16px; */
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 26px;
    color: #3BB9C4;
}

.personCardAdditionalFieldsCols {
    display: flex;
}

.personCardAdditionalFields {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
}

.personCardAdditionalFieldLabel {
    margin-top: 32px;
    /* margin-bottom: -13px; */
    margin-left: 27px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0C3957;
}

.personCardAdditionalInput, .personCardAdditionalInput:focus {
    width: 159px;
    height: 39px;
    padding: 0px 0px 0px 23px;
}

.marginalTax {
    display: flex;
    flex-direction: column;
}

.cashSignLabel {
    top: 31px;
    left: 35px;
    width: 0px;
    position: relative;
    align-items: center;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    z-index: 1;
    color: #0C3957;

}