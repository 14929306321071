.editUserButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 110px;
  width: 100%;
}

.editUserPasswordField {
  position: relative;
  top: 80px;
  -webkit-user-select: none;
  user-select: none;
}
