.nextStepContainer {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-left: 30px;
    width: 491px;
    display: flex;
}

.nextStepTextContainer {
    width: 227px;
}

.nextStepDivider {
    position: relative;
    width: 38px;
    display: flex;
    height: auto;
}

.nextStepTitle {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #0C3957
}

.nextStepText {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957
}

.dividerDot {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 14px;
    top: 4px;
    z-index: 2;
}

.dividerLine {
    position: absolute;
    align-self: left;
    width: 4px !important;
    top: 20px;
    left: 18px !important;
    padding: 0px;
}

.nextStepIllustration {
    width: 227px;
    padding-top: 14px;
}

.nextStepText {
    padding-top: 10px;
}