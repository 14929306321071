.summaryHeaderContainer {
  display: flex;
  justify-content: flex-start;
  padding-top: 30px;
  padding-left: 40px;
  height: 40px;
  margin-bottom: 30px;
}

.finalScreen {
  display: flex;
  flex-direction: column;
  padding: 0px;
  height: 800px;
  width: auto;
  background-color: rgb(51, 51, 51, 0.05);
}

.summaryContainer {
  margin-left: 40px;
}

.summaryTopContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 1280px;
}

.summaryGoalsLeftPanel {
  width: 387px;
  height: 397px;
  background: #ffffff;
  /* box/standard */

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-right: 19px;
}

.summaryLeftPanelHeading {
  padding-top: 30px;
  padding-left: 20px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 30px;

  /* Text/Copy/Primary */

  color: #0c3957;
}

.goalSummaryItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
  margin-left: 30px;
}

.summaryGoalText {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  /* or 139% */

  display: flex;
  align-items: center;
  height: 36px;
  margin-left: 18px;

  /* Text/Copy/Primary */

  color: #0c3957;
}

.summaryGoalsRightPanel {
  width: 794px;
  height: 397px;
  background: #ffffff;
  /* box/standard */

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.summaryNextStepsIntroText {
  height: 52px;
  width: 700px;
  margin-top: 15px;
  margin-left: 20px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  /* Text/Copy/Primary */

  color: #0c3957;
}

.summaryNextSteps {
  margin-top: 30px;
  margin-left: 20px;
}

.summaryStep {
  margin-top: 22px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.summaryNextStepsText {
  margin-left: 15px;
  height: 36px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  /* or 139% */

  display: flex;
  align-items: center;

  /* Text/Copy/Primary */

  color: #0c3957;
}

.summaryMiddlePanelHeading {
  margin-top: 30px;
  margin-left: 40px;
  width: 776px;
  height: 30px;
  left: 40px;
  top: 525px;

  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 30px;

  /* Text/Copy/Primary */

  color: #0c3957;
}

.summaryMiddlePanelCheckboxes {
  display: flex;
  align-items: flex-start;
  margin-left: 40px;
  height: 102px;
}

.summaryCheckboxText {
  width: 520px;
  height: 82px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */

  /* Text/Copy/Primary */

  color: #0c3957;
}

.summaryCheckbox {
  width: 571px;
}

.summaryCheckboxLeft .summaryCheckboxRight {
  display: flex;
}

.summaryBottomContainer {
  margin-top: 26px;
  margin-left: 40px;
  display: flex;
}

.fieldBox {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  /* Text/Copy/Primary */

  color: #0c3957;
}

.fieldInputBox {
  height: 46px;
  width: 250px;
  background: #f5f8fa;
  border: 2px solid #3bb9c4;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.emailInputBox {
  width: 350px;
}

.emailInput {
  width: 300px;
  text-align: center;
}

.fieldInput,
input:focus {
  border: none;
  outline: none;
  height: 26px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  /* Text/Copy/Primary */

  color: #0c3957;
}

.sendButton {
  margin-left: 10px;
  margin-top: 25px;
}

.ammoniteFCANumber {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  margin-left: 80px;
  justify-content: flex-start;
  align-items: flex-start;
}

.blockInputPane:not(.confirmBox) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
}

.confirmBox {
  position: absolute;
  top: 173px;
  left: 390px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 400px;
  background-color: white !important;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
}

.closeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  /* display: flex;
	align-items: center;
	justify-content: center; */
  top: 2px;
  right: 8px;
  width: 52px;
  padding: 23px;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #bdbdbd;
}

.closeIcon {
}

.closeText {
  position: relative;
  left: -5px;
  top: -4px;
}

.confirmBoxText {
  display: inline;
  margin-top: 200px;
}

.confirmBoxBoldText {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #0c3957;
  padding-left: 30px;
  padding-right: 30px;
}

.confirmBoxNormalText {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #0c3957;
  padding-top: 6px;
  padding-left: 30px;
  padding-right: 30px;
}

.confirmationBalloons {
  position: absolute;
  right: 124px;
  top: 20px;
}

.finishButton {
  position: relative;
  top: 0px;
  left: 137px;
}

.emailCollisionText {
  color: #b40125;
}

.emailCollisionCloseButton {
  top: 96px;
}

.emailCollisionWarning {
  margin-top: 0px;
}

.centeredLoader {
  display: flex;
  height: 800px;
  align-items: center;
  justify-content: center;
}
