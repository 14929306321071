.sRmenuChips {
    margin-top: 22px;
}

.reportForContainer {
    position: absolute;
    top: 84px;
    left: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 0px;
}

.keyInformationTitle {
    position: absolute;
    top: 156px;
    user-select: none;
}

.keyInformationPanel {
    position: absolute;
    height: 375px;
    left: 33px;
    right: 853px;
    top: 204px;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.suitabilityRBField {
    display: flex;
}

.suitabilityRBFieldText {
    margin-left: 15px;
    margin-bottom: 5px;
    margin-top: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
}

.suitabilityRBGoalFocus {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.suitabilityRBGoalFocusTopRow {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    height: 70px;
}

.focusButton {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    height: 60px;
    width: 70px;
    padding: 0 30px 0 20px;
}

.newPoliciesTitle {
    position: absolute;
    left: 531px;
}

.newPoliciesContainer {
    position: absolute;
    top: 80px;
    left: 500px;
    display: flex;
}

.newPoliciesCol {
    display: flex;
    flex-direction: column;
}

.newPolicy {
    margin: 10px;
}

.chipPanel {
    position: absolute;
    top: 590px;
    left: 5px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 1;
}

.adviserSelect {
    position: absolute;
    left: 34px;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
}

.srbLoader {
    position: absolute;
    left: 50%;
    top: 50%;
}