.sessionContainer {
  position: relative;
  height: 104px;
  width: 1160px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5px 16px rgb(0 0 0 / 15%);
  border-radius: 8px;
  margin: 20px;
  display: flex;
  /* white */

  background: #ffffff;
  /* white */

  border: 1px solid #ffffff;
  box-sizing: border-box;
  /* box/standard */

  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.sessionCustomers {
  font-size: 14px !important;
  white-space: nowrap;
  overflow-x: hidden;
  margin-top: 20px;
  margin-left: 100px;
  width: 300px;
}

.sessionGroup {
  width: 250px;
  display: flex;
}

.sessionUUID {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  z-index: 1;
}

.sessionLabel {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  z-index: 1;
}

.blueBox {
  position: absolute;
  top: 0px;
  left: 500px;
  background-image: linear-gradient(to right, #f5f8fa, #3bb9c4);
  height: 37px;
  width: 643px;
  z-index: 0;
}

.sessionRight {
  position: absolute;
  top: 0px;
  left: 511px;
  z-index: 2;
}

.sessionDelete {
  position: absolute;
  top: 0px;
  left: 310px;
  z-index: 2;
}

.sessionSR {
  position: absolute;
  top: 0px;
  left: 384px;
  z-index: 2;
}

.greenDot {
  margin-top: 2px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #39b54a;
}

.redDot {
  margin-top: 2px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: #b40125;
}

.tabContainer {
  position: absolute;
  left: -2px;
  top: -4px;
  width: 46px;
  height: 112px;
}

.firstCustomer {
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: #0c3957;
}

.secondCustomer {
  margin-left: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #0c3957;
}

.buttonChips {
  margin-top: 20px;
  max-width: 600px;
  z-index: 1;
  display: flex;
}
.goalReportChip {
  padding-left: 20px;
}
.sessionIconContainer {
  position: relative;
  max-width: 400px;
  padding-left: 40px;
}
.sessionCustomer {
  font-size: 14px !important;
  white-space: nowrap;
  overflow-x: hidden;
  margin-top: 20px;
  width: 400px;
}
.Line {
  flex-direction: initial;
  position: absolute;
  bottom: 0;
  margin-left: 70px;
  width: 200px;
  margin-block-end: 15px;
}
