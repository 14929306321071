.callbackCardContainer {
    width: 555px;
    height: 2542px;
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 30px;

    /* white */

    background: #FFFFFF;
    /* box/standard */

    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
    padding-bottom: 30px;
}

.innerCallbackCardContainer {
    height: 2532px;
    overflow: hidden;
}

.financialPlanPoint {
    display: flex;
    margin-bottom: 10px;
}

.bulletPoint {
    width: 10px;
    padding-right: 10px;
}