.reportContainer {
  background: #f5f8fa;
  width: 595px;
  height: 13000px;
  padding-bottom: 0.5px;
  transform-origin: top left;
  transform: scale(2, 2);
}

@media only screen and (max-width: 600px) {
  .reportContainer {
    transform: matrix(0.62, 0, 0, 0.62, 0, 140);
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    transform-origin: 0 0;
    margin-top: 0px;
    margin-left: 17.5px;
    margin-right: 20px;
    padding: 30px;
    background-color: #f5f8fa;
    flex: 1;
    height: 13000px;
  }
}

@media only screen and (max-width: 400px) {
  .reportContainer {
    transform: matrix(0.62, 0, 0, 0.62, 0, 0);
    margin-top: 0px;
    margin-left: -2px;
    margin-right: 20px;
    padding: 30px;
    background-color: #f5f8fa;
    flex: 1;
    height: 13000px;
  }
}

@media only screen and (max-width: 380px) {
  .reportContainer {
    transform: matrix(0.6, 0, 0, 0.6, 0, -20);
    margin-top: 0px;
    margin-left: 2px;
    margin-right: 20px;
    padding: 30px;
    background-color: #f5f8fa;
    flex: 1;
    height: 13000px;
  }
}

.reportIntro {
  width: 555px;
  height: 132px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0c3957;
}

.reportGoalIntro {
  width: 555px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0c3957;
}

.aboutParagraph {
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #0c3957;
  margin-bottom: 30px;
}

.reportGoalCardsRow {
  padding-bottom: 20px;
  display: flex;
}

.reportSignature {
  position: relative;
  margin: 0px;
  transform: scale(0.5);
  left: -60px;
}

.advisorSignature {
  margin-top: -40px !important;
  margin-bottom: 60px;
  height: 20px !important;
}

a {
  text-decoration: none;
  color: #bf802f;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 30px;
}

h1 {
  padding-left: 20px;
  padding-top: 30px;
  font-family: Brother 1816;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
  color: #bf802f;
}

.yourGoals {
  display: inline-flex;
  flex-direction: column;
}

.afterInflation {
  /* align-self: flex-end;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957;
    z-index: 3; */
}

.reportGraphic {
  position: relative;
  height: 570px;
}

.callBackCard {
  height: 2542px;
}
