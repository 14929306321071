.contextBars {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bottomBar {
    position: absolute;
    bottom: 0px;
    width: 1260px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.home {
    padding-right: 20px;
}

.documentLibraryPanel {
    padding: 20px;
}

.currentPanel {
    margin-top: 20px;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 46px;
    color: #BF802F;
}

.currentTitle {
    margin-top: 65px;
}

.historyInnerPanel {
    overflow-y: auto;
    overflow-x: hidden;
    height: 325px;
}

.currentInnerPanel {
    height: 90px !important;
    overflow-y: hidden;
}

.loaderAnimation {
    position: absolute;
    top: 250px;
    left: 600px;
}

.docPopUpBox {
    position: absolute;
    border-radius: 20px;
    top: 233px;
    left: 390px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 250px;
    background-color: white !important;
    box-shadow: 0px 5px 16px rgb(0 0 0 / 15%);
}

.closeButton {
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer; 
	position: absolute;
	padding: 15px;
	font-size: 16px;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: left;
	color: #BDBDBD;
}

.closeText {
	position: relative;
    left: -5px;
    top: -4px;
}

.docPopUpBoxInfo {
    margin-top: 70px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.docPopUpBoxText {
	color: #0C3957;
	padding-top: 6px;
    padding-bottom: 40px;
}

.takeToPreviewInfo {
    margin-top: 60px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.takeToPreviewText {
	color: #0C3957;
	padding-top: 6px;
    padding-bottom: 50px;
}
