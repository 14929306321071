.newPolicyCard {
    height: 150px;
    width: 350px !important;
    background: #FFFFFF;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-top: 0px;
}

.newPolicyCardFieldContainer {
    position: relative;
    padding-top: 10px;
}

.newPolicyField {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
}

.newPolicyFieldTitle {
    margin-left: 20px;
}

.newPolicyCardIcons {
    position: relative;
    top: -126px;
    left: 274px;
    display: flex;
}

.newPolicyCardTrashcan {
    margin-top: -3px;
    margin-left: 40px;
}

.newPolicyCardEdit {
    margin-top: -4px;
}