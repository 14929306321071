.contextBar {
    display: flex;
    align-items: center;
    height: 75px;
    width: 1238px;
    background: #FFFFFF;
    padding: 5px;
}

.tabColour {
    height: 75px;
    width: 50px;
    background: #2B9BA5;
}

.isBarCurrent {
    background: #BF802F !important;
}

.barInfo {
    padding: 20px;
    display: flex;
}

.barTitle {
    justify-content: center;
    width: 200px;
    height: 35px;
    padding-left: 0px;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #0C3957;
}

.barDate {
    padding-left: 100px;
}

.contextButtons {

    display: flex;
    padding-top: 10px;
    padding-left: 130px;
}

.contextButton {
    padding: 10px;
}