.unemployed {
    color: "#850944";
}

.financialPlanningCardContainer {
    width: 556px;
    margin-left: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 8px;
}

.financialPlanningCardHeader {
    padding-top: 24.5px;
    padding-left: 23px;
    display: flex;
    align-items: center;
    margin-bottom: 14.5px;
}

.financialPlanningCardHeaderText {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    padding-left: 8px;
}

.financialPlanningCardText {
    margin-bottom: 20px;
}

.financialPlanningParagraph {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.investmentSubHeading {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957;
}

.chargesSubText {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #0C3957;
}