@import '../../index.css';

.screen {
	display: flex;
	flex-direction: column;
	padding: 0px;
	height: 800px;
	width: auto;
	background-color: rgb(51, 51, 51, 0.05);
}

.mainContainer {
	height: 590px;
	width: 800px;
}

.headerContainer {
	display: flex;
	justify-content: flex-start;
	padding-top: 30px;
	padding-left: 40px;
	height: 40px;
	user-select: none;
}

.introContainer {
	display: flex;
	justify-content: flex-start;
	padding-top: 10px;
	padding-bottom: 0px;
	padding-left: 40px;
	height: 45px;
	width: 700px;
	user-select: none;
}

.buttonsContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 10vh;
}

.progressBarContainer {
	position: absolute;
	top: 699px;
}

.buttonsBox {
	position: absolute;
	left: 0px;
	top: 712px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	flex-direction: row;
	height: 88px;
	width: 1280px;
	background-color: #ffffff;
	padding: 0px;
}

.howWeWorkedThisOut {
	margin-left: 40px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 26px;
	display: flex;
	align-items: center;
	color: #7A7A7A;
}

.menuButtonContainer {
	position: absolute;
	top: 36px;
	right: 40px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
}

.sectionProgressContainer {
	padding-right: 20px;
	user-select: none;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: #7A7A7A;
}

.navigationButtons {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	flex: 2 2 25em;
}

.buttonResetContainer {
	margin: 0px 30px 0px 0px;
}

.buttonBackContainer {
	margin: 0px 30px 0px 0px;
}

.buttonNextContainer {
	margin: 0px;
}

.menuContainer {
	position: absolute;
	top: 0;
	left: 0;
	width: 450px;
	height: 100vh;
	background-color: white;
	z-index: 1;
	transition: margin 0.2s ease-in;
	margin: 0 0 0 1723px;
}

.menuContainer.isOpen {
	margin: 0 0 0 830px;
	transition: margin 0.2s;
}

.howWeWorkedThisOutDropdownIcon{
	margin-top: 10px;
	margin-left: 10px;
}

.howWeWorkedThisOutIsOpen{
	transform: scaleY(-1);
	margin-top: 0px;
}

.howWeWorkedThisOutCopy {
	overflow-y: scroll;
	position: absolute;
	bottom: 0px;
	width: 1280px;
	height: 566px;
	background: #FFFFFF;
	z-index: 4;
}

.howWeWorkedThisOutText {
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 26px;
	color: #0C3957;
	position: absolute;
	top: 96px;
    left: 40px;
    width: 1200px;
}

.howWeWorkedThisOutCloseButton {
	position: fixed;
	top: 235px;
	left: 10px;
}

.howWeWorkedThisOutCloseButtonText {
	position: absolute;
	top: 27px;
	right: 70px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	display: flex;
	align-items: center;
	letter-spacing: 0.02em;
	color: #7A7A7A;
}

.modalScreen {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 1280px;
	height: 800px;
	background: rgba(0, 0, 0, 0.5);
	z-index: 3;
}

.howWeWorkedThisOutTitle {
	position: absolute;
	top: 30px;
	left: 40px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 30px;
	display: flex;
	align-items: center;
	color: #BF802F;
}

.arrow-wrap {
	position:fixed;
	left:1190px;
	top: 710px;
  }