.goalSummaryMainContainer {
	margin-left: 40px;
}

.topRowGoalSummaries {
	display: flex;
	align-items: center;
}

.topRowGoalSummariesLast {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.bottomRowGoalSummaries {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

.afterGoalInflation {
	position: absolute;
	top: 685px;
	right: 40px;
}

.asterisk {
	color: #2B9BA5;
	font-weight: bold;
    display: inline-block;
    margin: 0px;
}