.advisorCard {
    background: #FFFFFF;
    /* box/standard */
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 555px;
    margin-left: 20px;
}

.advisorAvatarContainer {
    display: flex;
    margin-top: 30px;
    margin-left: 20px;
}

.avatarTitleContainer {
    margin-left: 20px;
    width: 374px;
}

.avatarTitleContainer {
    margin-left: 20px;
}

.advisorName {
    margin-top: 35px;
    margin-bottom: 0px;
}

.advisorQualification {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: #0C3957;
}

.advisorAboutHeader {
    margin-top: 15px;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #0C3957;
    margin-left: 20px;
}

.advisorAbout {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

h2 {
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #BF802F;
}