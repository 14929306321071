.addGoalInstructions {
    position: absolute;
    width: 590px;
    height: 23px;
    left: 43px;
    top: 79px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957;
}

.goalCardsEditDetails {
    position: absolute;
    left: 40px;
    top: 122px;
}