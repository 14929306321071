.customerReportContainer {
  display: flex;
  flex: 1;
  width: 1280px;
  height: 800px;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media only screen and (max-width: 600px) {
  .customerReportContainer {
    /* background-color: lightblue; */
    height: 800px;
    width: calc(100% + 20px);
    margin-left: 0px;
    overflow-x: inherit;
    margin-left: -20px;
    margin-right: 20px;
  }
}

.customerReportContainer::-webkit-scrollbar {
  display: none;
}
