.pageTitleText {
/* Used in: Additional Customer Info, Additional Goal Deatils, Attitude to Risk, Children Details, Document Library,
Existing investment details, Existing pension details, Fact finder builder, Further customer info, New cash advice,
New GIA advice, New ISA advice, New LOA advice, New Pension advice, Suitability Dashboard, Suitability report builder pages.*/
    position: absolute;
    width: 591.02px;
    height: 39.21px;
    top: 30px;
    left: 40px;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 46px;
    color: #BF802F;
    user-select: none;
}

.pageInfoText {
/* Used in: Additional Customer Info, Attitude to Risk, Children Details and Suitability Report pages. */
    position: absolute;
    display: flex;
    align-items: center;
	justify-content: left;
    top: 86px;
    left: 40px;
    width: 580px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #0C3957;
}

.pageMainText {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card,
Suitability dashboard, Suitability report builder files. */
    width: 700px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    font-family: Brother 1816;
    color: #0C3957;
    user-select: none;
}

.suitabilityPanel {
/* Used in: Additional customer info, Additional goal details, Attitude to risk, Children details, Document library, Existing investment details,
Existing pension details, Fact finder builder, Further customer info, New cash advice, New GIA advice, New ISA advice, New LOA advice, 
New pension advice, Suitability dashboard, Suitability report builder pages. */
    width: 1280px;
    height: 800px;
    background-color: #F5F8FA;
}

.menuBar {
/* Used in: Additional customer info, Additional goal details, Attitude to risk, Children details, Existing investment details,
Existing pension details, Fact finder builder, Further customer info, New cash advice, New GIA advice, New ISA advice, New LOA advice,
New pension advice, Suitability dashboard, Suitability report builder pages.*/
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    bottom: 0px;
    width: 1280px;
    height: 102px;
    background-color: #ffffff;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
}

.menuChips {
/* Used in: Additional customer info, Additional goal details, Attitude to risk, Children details, Existing investment details, 
Existing pension details, Fact finder builder, Further customer info, New cash advice, New GIA advice, New ISA advice, New LOA advice,
New pension advice, Suitability dashboard, Suitability report builder pages. */
    margin: 21px;
    display: flex;
    align-items: start;
}

.menuChip {
/* Used in: Suitability dashboard, Suitability report builder pages. */
    margin-right: 11px;
}

.softFactsPanel {
/* Used in: Additional customer info, Attitude to risk, Fact finder builder pages. */
    position: absolute;
    top: 145px;
    left: 40px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 1200px;
    height: 518px;
    background-color: #ffffff;
    box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
}

.whyInput, .whyInput:focus {
/* Used in: Additional customer info, Attitude to risk, Fact finder builder, Goal allocate, Investment card, New cash advice card, New GIA advice card, New ISA advice card, New LOA advice card,
New pension advice card, Pension card, Person card additional files. */
	background: #f5f8fa;
	border: none;
	border-radius: 4px;
	height: 46px;
	width: 1100px;
    margin: 0px 25px;
	padding: 10px 23px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: #0c3957;
}

.notesInput, .notesInput:focus {
/* Used in: Additional customer info, Fact finder builder */
    height: 200px;
    border: 0;
    outline: none;
    font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: #0c3957;
}

.pensionFieldInput, .pensionFieldInput:focus {
/* Investment card, New cash advice card, New GIA advice card, New ISA advice card, New LOA advice card, New pension advice card,
Pension card files. */
    height: 17px;
    margin-right: 60px;
}

.pensionAdviceFieldInput {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, New pension advice card files. */
    width: 125px !important;
}

.multipleChoiceValues {
/* Used in: Person card additional, Attitude to risk files. */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.multipleChoiceButtonLabel {
/* Used in: Person card additional, Attitude to risk files. */
    margin-top: -30px;
    padding-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    /* Text/Copy/Primary */

    color: #0C3957;
}

.emptyCardButtonHandlerContainerSR {
/* Used in: Person card additional, Attitude to risk files. */
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-left: 27px;
    height: 60px;
    width: 100px;
}

.buttonHandlerContainer {
/* Used in: Person card additional, Attitude to risk files. */
    margin-top: 4px;
}

.financiallyDependent {
/* Used in: Investment card, Pension card, Child files. */
    margin: -20px 22px;
}

.currentPension {
/* Used in: Existing investment details, Existing pension details, New cash advice, New GIA advise, New ISA advice, New LOA advice,
New pension advice pages.  */
    position: absolute;
    top: 100px;
    left: 140px;
}

.prevPension {
/* Used in: Existing investment details, Existing pension details, New cash advice, New GIA advice, New ISA advice, New pension advice. */
    position: absolute;
    top: 346px;
    left: 40px;
    transform: scaleX(-1);
}

.nextPension {
/* Used in: Existing investment details, Existing pension details, New cash advice, New GIA advice, New ISA advice, New pension advice. */
    position: absolute;
    top: 346px;
    right: 40px;
}

.pensionOfPensions {
/* Used in: Existing investment details, Existing pension details, New cash advice, New GIA advice, New ISA advice, New pension advice. */
    position: absolute;
    top: 48px;
    right: 130px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #7A7A7A;
}

.addPension {
/* Used in: Existing investment details, Existing pension details, New cash advice, New GIA advice, New ISA advice, New pension advice. */
    display: flex;
    position: absolute;
    top: 30px;
    left: 62px;
}

.addPensionText {
/* Used in: Existing investment details, Existing pension details, New cash advice, New GIA advice, New ISA advice, New pension advice. */
    margin-left: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #0C3957;
    user-select: none;
}

.pensionCard {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New LOA advice card, New pension advice card,
Pension card. */
    height: 580px;
    width: 1000px !important;
    margin-right: 20px;
    display: flex;
    margin-top: -10px;
    padding: 8px;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	background-color: #ffffff;
}

.pensionCol {
/* Used in: Investment cars, New cash advice card, New GIA advice card, New ISA advice card, New Pension advice card, Pension card */
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    
}

.newPensionFieldContainerMargin {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card. */
    margin-left: 10px;
}

.newPensionFieldContainer {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card. */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 11px;
    width: 501px !important;
    height: 35px;
}

.ownerPension {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card,
Suitability report builder. */
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 400px;
}

.ownerPensionButton {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card,
Suitability report builder. */
    margin-top: 30px;
}

.customerLabel {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card,
Suitability report builder */
    margin-top: -12px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
    user-select: none;
}

.transferPension {
/* Used in: Investment card, Pension card. */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 212px;
    margin-top: 0px;
}

.transferPensionText {
/* Used in: Investment card, Pension card. */
    margin-top: 22px;
    margin-right: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
    color: #BF802F;
    user-select: none;
}

.pensionTrashcan {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card. */
    position: relative;
    top: 0px;
    left: 450px;
}

.subFieldContainerMargin {
/* Used in New pension advice card, Pension card. */
    margin-left: 30px;
    margin-right: -30px;
}

.goalsAllocationContainer {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, New pension advice card. */
    position:absolute;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-top: 350px;
    margin-left: -290px;
}

.goalAllocationTotal {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, New pension advice card. */
    position: absolute;
    top: 450px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #0C3957;
}

.investmentStrategyLabel {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card. */
    margin-top: 27px;
}

.investmentStrategyButton {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, New pension advice card. */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.pensionAdviceFieldContainer {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, New pension advice card. */
    margin-left: 0px;
    width: 600px !important;
    justify-content: flex-start;
}

.advice {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, New pension advice card. */
    width: 250px;
}

.labelOwner {
/* Used in: Investment card, New cash advice card, New GIA advice card, New ISA advice card, New pension advice card, Pension card,
Suitability report builder. */
    width: 370px;
}

.why {
/* Used in: Additional customer info, Fact finder builder. */
    margin-top: 17px;
    margin-left: 25px;
    margin-bottom: 5px;
}

.pensionFieldContainer {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, Suitability report builder. */
    display: flex;
    width: 380px !important;
    margin-left: 11px;
    margin-top: -19px;
}

.goalsTotalPercent {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card, New pension advice card. */
    margin-left: -150px;
}

.investmentStrategyButtonWidth {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card. */
    width: 100px;
}

.investmentStrategyFieldContainer {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card. */
    width: 770px !important;
}

.pensionInvestmentStrategy {
/* Used in: New cash advice card, New GIA advice card, New ISA advice card. */
    margin-top: 36px;
    margin-left: -8px;
    position: absolute;
    top: 472px;
    left: 10px;
}