.sessionCardMask {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgb(122, 122, 122, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.sessionCard {
  max-width: 944px;
  min-height: 688px;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  /* display: flex;
  flex-direction: column;
  justify-items: flex-end; */
  margin: 40px;
  background: #ffffff;
  z-index: 4;
}

.sessionSummaryName {
  color: #bf802f;
  font-family: Brother 1816;
  font-weight: 500;
  font-size: 25px;
  margin-top: 20px;
  margin-left: 25px;
}

.sessionSummaryCardData {
  margin-left: 25px;
  justify-content: center;
  align-items: center;
}

.sessionSummaryNavigation {
  /* justify-self: flex-end;
  align-self: flex-end; */
}

.sessionDataSendButton {
  padding-bottom: 7px !important;
  padding-right: 15px !important;
}
