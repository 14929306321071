.reportViewerContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #2B9BA5;
    width: 1280px;
    height: 800px;
    padding-bottom: 20px;
    overflow: hidden;
}

.sendingEmailBlockInteraction{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 800px;
    width: 1280px;
    z-index: 10;
    background-color: rgb(43,155,165, 0.7);
}

.sendingEmailBlockInteractionText {
    position: absolute;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Brother 1816;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 43px;
    color: #BF802F;
}

.reportViewerTopBar {
    height: 100px;
    width: 1280px;
    background: #2B9BA5;
    align-self: center;
    z-index: 2;
}

.reportViewerReportOuterContainer {
    margin-top: -100px;
    height: 1000px;
    width: 1280px;
    height: 800px !important;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    overflow: hidden;
}

.reportViewerReportContainer {
    margin: 0px;
    transform: scale(0.7) translate(0, 0px);
    width: 1190px;
    height: 860px !important;
    overflow-x: hidden;
}

.reportViewerReportContainer::-webkit-scrollbar {
    display: none;
  }