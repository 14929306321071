/* NEW PENSION ADVICE PAGE */

.investmentStrategyFieldLabel {
    margin-left: 20px;
}

.newPensionsInvestmentStrategy {
    position: absolute;
    top: 472px;
    left: 10px;
}

.includeAnnualAllowanceDetailsCheckboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.includeAllowanceDetailsLabel {
    margin-top: 25px;
    margin-left: -15px;
}

.newPensionInvestmentStrategyButtonsContainer {
    margin-left: 190px;
}

.newPensionInvestmentButtonWidth {
    width: 150px;
}

.newPensionInvestmentStrategyFieldLabel {
    position: absolute;
    margin-top: 38px;
    margin-left: 8px;
}

/* NEW CASH ADVICE PAGE */

.allocationOfGoalsNewCash {
    margin-top: 60px;
}