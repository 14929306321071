@import '../../index.css';

.bannerContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 2rem;
	margin-right: 2rem;
}

.banner {
	font-size: 1.5rem;
}
