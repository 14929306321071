.stepperContainer {
  display: flex;
  flex-Direction: column;
  align-items: center;
}

.stepperCard {
  display: flex;
  align-items: center;
}

.stepperStep {
  position: absolute;
  top: 726px;
  left: 0;
  width: 1280px;
  /* transition: margin 0.2s ease-in; */
}

.skipIntro {
  position: absolute;
  top: 726px;
  right: 122px;
  font-family: Brother;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  border-bottom: solid 1px #7A7A7A;
  color: #7A7A7A;
}