.topBarContainer {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	width: 1200px;
	height: 80px;
	margin-top: 23px;
	margin-bottom: 21px;
	padding-left: 18px;
	background: #ffffff;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
}

.comforts {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -7px;
	width: 131px;
}

.topBarDivider {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
}

.retirementAge {
	width: 280px;
	padding-left: 20px;
	padding-right: 20px;
	justify-content: flex-end;
    font-size: 18px;
	line-height: 26px;
	color: #0c3957;
	margin-left: 100px;
}

.retirementAgeLabel {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	/* or 162% */

	display: flex;
	align-items: right;
	/* Text/Copy/Primary */

	color: #0c3957;
	margin-left: 15px;
	user-select: none;
}

.alignRight {
	display: flex;
	flex-direction: row;
	text-align: right;
}

.boldMargin {
	font-weight: bold;
	margin-left: 20px;
}

.topBarSecondDivider {
	padding: 0.5px;
}

.topBarThirdDivider {
	margin-left: 10px;;
}

.topBarIsBold {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 26px;
}
