.roundSwitchContainer {
	height: 30px;
	display: flex;
	justify-content: start;
	align-items: center;
	margin-left: 7px;
	margin-right: 10px;
}

.roundSwitch {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-right: 10px;
	width: 36px;
}

.switchText {
	font-family: Brother 1816;
	font-style: normal;

	font-size: 16px;
	line-height: 26px;
	/* identical to box height, or 162% */

	display: flex;
	align-items: center;
	letter-spacing: 0.01em;
	padding: 0px;

	/* Text/Copy/Primary */

	color: #0c3957;
}

.isBold {
	font-weight: bold;
}
