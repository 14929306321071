.investmentAfterRecommendationsContainer {
    position: relative;
    height: 256px;
    width: 1143px;
    background: #F5F8FA;
    border-radius: 4px;
}

.investmentAfterRecommendationsField {
    display: flex;
    height: 36px;
}

.investmentAfterRecommendationsItem{
    position: absolute;
    left: 265px;
}