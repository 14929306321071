.personCard {
	width: 524px !important;
	height: 457px;
	box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	background-color: #ffffff;
	margin-right: 20px;
}

.personHeader {
	display: flex;
}

.personalDetails {
	padding-top: 23px;
	padding-left: 20px;
	width: 178px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: bold;
	font-size: 21px;
	line-height: 30px;
	color: #0c3957;
	user-select: none;
}

.genderContainer {
	width: 400px;
}

.nameContainer,
.genderContainer,
.dobContainer {
	margin-top: 14px;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
}

.nameFields {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: start;
	height: 66px;
}

.dobContainer {
	display: block;
}

.dobInput {
	height: 39px;
	width: 183px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	background-color: #f5f8fa;
	padding-left: 20px;
}

.dobInput:hover {
	box-sizing: border-box;
	border: 2px solid #3bb9c4;
}

.nameLabel,
.genderLabel {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	color: #0c3957;
	user-select: none;
}

.field {
	background: #f5f8fa;
	border: none;
	border-radius: 4px;
	height: 26px;
	padding: 10px 23px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #7a7a7a;
}

.dob {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 26px;
	/* identical to box height, or 162% */

	letter-spacing: 0.01em;

	/* Text/Copy/Primary */

	color: #0c3957;
}

.name {
	text-align: start;
	width: 116px;
}

.dateName {
	text-align: start;
	width: 145px;
	height: 30px;
}

.genderButtons {
	display: flex;
	align-items: center;
	justify-content: start;
	height: 46px;
	width: 416px;
}

.avatar {
	margin-top: 18px;
	margin-left: 210px;
	margin-bottom: -38px;
}

.buttonCardHandlerContainer {
	margin: 0px;
}

.emptyCardButtonHandlerContainer {
	margin-top: 0px;
}

.filledButtonCardHandlerContainer {
	margin-top: 14px;
}

.gender {
	display: flex;
	align-items: center;
	justify-content: start;
	padding-right: 61px;
}

.bottomMargin {
	margin-bottom: 10px;
}

.work {
	padding-right: 0px;
}

.workLabel {
	margin-top: 10px;
	margin-bottom: 10px;
}

.genderText {
	padding-left: 10px;
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	color: #0c3957;
	user-select: none;
}

.employedText {
	width: 99px;
}

.selfEmployedText {
	width: 115px;
}

.otherText {
	width: 84px;
}

.valid {
	border: 2px solid #B40125 !important;
	border-radius: 4px;
}

.customerDeleteContainer {	
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	margin-top: 2px;
	margin-left: 14px;
	position: absolute;
	height: 35px;
	left: 981px;
	right: 143.99px;
	top: 224px;
}


.customerDeleteIcon {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.customerDeleteText {
	font-family: Brother 1816;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 25px;
	color: #7A7A7A;
	order: 1;
	padding: 0px 5px;
}
